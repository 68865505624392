<template>
  <div class="row">
      <div class="col-md-12 col-xl-12">
          <el-row :gutter="20" class="mb-g search-thong-bao">
              <el-col :xs="24" :sm="16" :md="8" :lg="6" :xl="4" class="mt-2">
                  <el-date-picker
                          class="w-100"
                          v-model="date"
                          type="month"
                          placeholder="Chọn tháng"
                  >
                  </el-date-picker>
              </el-col>
              <el-col :xs="24" :sm="8" :md="4" :lg="3" :xl="2" class="mt-2">
                  <el-button
                          class="w-100"
                          type="primary"
                          @click="searchLuong"
                          icon="el-icon-search"
                  >Tìm kiếm
                  </el-button
                  >
              </el-col>
          </el-row>
          <div class="row">
              <div class="col-lg-12 col-xl-3 order-lg-1 order-xl-1">
                  <!-- profile summary -->
                  <div class="card mb-g rounded-top">
                      <div class="d-flex flex-row pb-3 pt-4 text-center  border-top-0 border-left-0 border-right-0">
                          <h4 class="mb-0 flex-1 text-dark fw-500">Thông tin cơ bản</h4>
                      </div>
                      <div class="row no-gutters row-grid">
                          <div class="col-12 p-4">
                              <h5 class="mb-3 col-xs-6">
                                  <span class="d-inline-block width-size col-4">Họ và tên: </span><span class="fw-700">{{ currentUser.name || (currentUser.user && currentUser.user.name) }}</span>
                              </h5>
                              <h5 class="mb-3 col-xs-6">
                                  <span class="d-inline-block width-size col-4">Mã nhân sự:</span><span class="fw-700">{{  (currentUser.id || currentUser.user.id) + 100000  }}</span>
                              </h5>
                              <h5 class="mb-3 col-xs-6">
                                  <span class="d-inline-block width-size col-4">Trung tâm dạy:</span><span class="fw-700">{{data && data.teaching_center }}</span>
                              </h5>
                          </div>
                      </div>
                  </div>


              </div>
              <div class="col-lg-12 col-xl-9 order-lg-3 order-xl-2">
                  <div class="panel">
                      <div class="panel-hdr">
                          <h2>
                              Phiếu lương tháng {{ date ? (date.getMonth() + 1)+ '/' + date.getFullYear() :
                              ((new Date().getMonth()) + '/' + new Date().getFullYear()) }}
                          </h2>
                      </div>
                      <div class="panel-container show">
                          <div class="panel-content">
                              <div class="row">
                                  <div class="col-lg-12 col-md-12">
                                      <el-row v-loading="loading"
                                              v-if="data.teacher_pay_slips && data.teacher_pay_slips.length > 0">
                                          <table class="table table-bordered table-hover table-striped w-100 dataTable dtr-inline">
                                              <template>
                                                  <tr>
                                                      <td class="text-center" style="width: 400px"><b>Danh mục</b></td>
                                                      <td class="text-center" style="width: 500px"><b>Số tiền</b></td>
                                                      <td class="text-center"><b>Chi tiết</b></td>
                                                  </tr>
                                                  <tr v-for="(item, i) in payslip_teacher_table" :key="i">
                                                      <template v-if="!item.last_step">
                                                          <td style="width: 400px">
                                                              <b v-if="item.bold">{{item.label}}
                                                              </b>
                                                              <span v-else>{{item.label}}</span>
                                                          </td>
                                                          <td style="width: 500px"><b v-if="item.key">
                                                              {{data[item.key].toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}
                                                          </b></td>
                                                          <td><span @click="detailPaySlip(data.payslipId,item.label,item.type)" v-if="item.isDetail" class="detail-payslip">Xem chi tiết</span></td>
                                                      </template>
                                                      <template v-else>
                                                          <td v-if="item.last_step" colspan="3" style="width: 500px">&nbsp;</td>
                                                      </template>
                                                  </tr>
                                              </template>
                                          </table>
                                      </el-row>
                                      <el-row v-loading="loading" v-else>
                                          <el-empty
                                                  :description="'Chưa có thông tin tháng lương của tháng ' + (date ? (( date.getMonth() + 1 ) + '/' + date.getFullYear()) : '')"></el-empty>
                                      </el-row>
                                  </div>
                              </div>
                              <p class="text-danger color-danger-700">(*) Đề nghị
                                  nhân sự bảo mật thông tin thu nhập của mình. <br>
                                  Mọi thắc mắc vui lòng liên hệ Trung tâm hỗ trợ.
                                  <br>Cảm ơn sự đóng góp của bạn vào kết quả chung của Công ty.</p>
                          </div>
                      </div>
                      <el-dialog class="responsive" :visible.sync="dialog.dialogDetailIsOpen" :title="dialog.title">
                          <el-row v-loading="loadingDetail">
                              <el-table
                                          :data="detailPaySlipData"
                                          style="width: 100%"
                                          :header-row-style="{textAlign: 'center'}"
                                          >
                                          <el-table-column
                                              prop="stt"
                                              label="STT"
                                              width="50"
                                              header-align="center"
                                              align="center"
                                              >
                                              <template
                                                  slot-scope="scope">
                                                  {{scope.$index + 1}}
                                          </template>
                                          </el-table-column>
                                          <el-table-column
                                              prop="class_name"
                                              label="Tên lớp"
                                              width="180"
                                              header-align="center"
                                              align="center"
                                              >
                                          </el-table-column>
                                          <el-table-column
                                              prop="center_name"
                                              label="Cơ sở"
                                              width="180"
                                              header-align="center"
                                              align="center"
                                              >
                                          </el-table-column>
                                          <el-table-column
                                              prop="description"
                                              label="Nội dung"
                                              header-align="center"
                                              align="center"
                                              >
                                          </el-table-column>
                                          <el-table-column
                                              prop="work_number"
                                              label="Số công"
                                              header-align="center"
                                              align="center"
                                              >
                                          </el-table-column>
                                          <el-table-column
                                              prop="unit_price"
                                              label="Đơn giá"
                                              header-align="center"
                                              align="center"
                                              >
                                              <template
                                                  slot-scope="scope">
                                                  <b>{{scope.row.unit_price != 0 ? scope.row?.unit_price.toLocaleString('vi-VN', {style : 'currency', currency : 'VND'}) : '0 ₫'}}</b>
                                          </template>
                                          </el-table-column>
                                          <el-table-column
                                              prop="amount_price"
                                              label="Thành tiền"
                                              header-align="center"
                                              align="center"
                                              >
                                              <template
                                                  slot-scope="scope">
                                                  <b>{{scope.row.amount_price != 0 ? scope.row?.amount_price.toLocaleString('vi-VN', {style : 'currency', currency : 'VND'}) : '0 ₫'}}</b>
                                          </template>
                                          </el-table-column>
                                          </el-table>
                          </el-row>
                          <div slot="footer" class="dialog-footer">
                              <el-button @click="dialog.dialogDetailIsOpen = false">Đóng</el-button>
                          </div>
                      </el-dialog>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
  import {PAY_SLIP_TEACHER,PAY_SLIP_TEACHER_DETAIL} from '@/core/services/store/service.module';
  import lodash from 'lodash-es';
  import deepdash from 'deepdash-es';
  import {PAYSLIP_TEACHER_TABLE} from '@/core/config/teacherPaySlip';
  const _ = deepdash(lodash);

  export default {
      components: {
      },
      data() {
          return {
              publicPath: process.env.VUE_APP_BASE_URL,
              data: {},
              loading: true,
              loadingDetail: true,
              isFocus: false,
              date: null,
              start_of_month: null,
              payslip_teacher_table : PAYSLIP_TEACHER_TABLE,
              dialog:{
                  title:"",
                  dialogDetailIsOpen: false,
              },
              detailPaySlipData : null,
          };
      },
      methods: {
          capitalize(s)
          {
              let index_number = s.indexOf("(");
              return s && (s[0].toLowerCase() + s.slice(1)).substring(0,index_number);
          },
          detailPaySlip(id,title,detail_type){
              this.loadingDetail = true;
              let params = {
                  id,
                  type:detail_type
              }
              this.dialog.dialogDetailIsOpen = true;
              this.dialog.title = "Chi tiết" + " " + this.capitalize(title) ;
              this.$store.dispatch(PAY_SLIP_TEACHER_DETAIL, params).then((response) => {
                  if (response.status === 422) {
                      this.$message({
                          type: 'warning',
                          message: `${response.message}`,
                          showClose: true,
                      });
                  } else {
                      this.detailPaySlipData = response.data;
                      this.loadingDetail = false; 
                  }
              });
          },
          queryServer(customProperties) {
              this.loading = true;
              let params = {};
              params = _.merge(params, customProperties);
              this.$store.dispatch(PAY_SLIP_TEACHER, params).then((response) => {
                  if (response.status === 422) {
                      this.$message({
                          type: 'warning',
                          message: `${response.message}`,
                          showClose: true,
                      });
                  } else {
                      this.data = response.data;
                      this.loading = false;
                  }
              });
          },
          searchLuong() {
              if (this.date) {
                  this.month = this.date.getMonth() + 1;
                  this.year = this.date.getFullYear();
              } else {
                  let lastMonth = this.$moment().subtract(1, 'months').format('YYYY-MM-DD');
                  this.date = new Date(lastMonth);
                  this.month = this.date.getMonth() + 1;
                  this.year = this.date.getFullYear();
              }
              this.start_of_month = this.year + '-' + (this.month < 10 ? '0' + this.month : this.month) + '-01';
              this.queryServer({
                  month: this.month,
                  year: this.year,
              });
          },
      },
      mounted() {
          this.$store.dispatch(SET_BREADCRUMB, [
              {title: 'Thu nhập', icon: 'fal fa-money-bill'},
          ]);
          let lastMonth = this.$moment().subtract(1, 'months').format('YYYY-MM-DD');
          this.date = new Date(lastMonth);
          this.month = this.date.getMonth() + 1;
          this.year = this.date.getFullYear();
          this.start_of_month = this.year + '-' + (this.month < 10 ? '0' + this.month : this.month) + '-01';

          this.queryServer({
              month: this.month,
              year: this.year,
          });
      },
      computed: {
          ...mapGetters(['currentUser']),
          // Get date from first day to last day of date's month
          rangeMonth() {
              let y = this.date.getFullYear();
              let m = this.date.getMonth() + 1;
              const firstDay = new Date(y, m, 1);
              const lastDay = new Date(y, m + 1, 0);

              return `Từ ${firstDay.getDate()}/${m}/${y} - ${lastDay.getDate()}/${m}/${y}`;
          },
      },
  };
</script>

<style>
.detail-payslip {
  text-decoration: underline;
  cursor: pointer;
  color: #472f92;
}
.el-table {
  font-size: 0.8125rem;
  color: #212529;
}
</style>
